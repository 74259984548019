import React, { useEffect, useState } from "react";
import PlacesAutocomplete, 
{ geocodeByPlaceId } from "react-places-autocomplete";
import { toast } from "react-toastify";
import './autocomplete.scss';
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';


export default function GooglePlacesAutocomplete({
    defaultValue,
    country,
    disabled,
    onSelectResult,
    watch,
    setError,
    clearErrors,
    errors
}) {
    const { t } = useTranslation()
    const [selected, setSelected] = useState('');

    useEffect(() => {
      if(defaultValue) {
        setSelected(defaultValue);
      }
    }, [country, defaultValue, disabled]);

    const handleSelectPlace = (place) => {
        setSelected(place);
    }

    const handleSelect = async (address, placeId, id) => {
      if(errors.direccion) clearErrors('direccion')
      const [place] = await geocodeByPlaceId(placeId);
      const {long_name: extra = ''} = place.address_components.find(c => c.types.includes('subpremise')) || {};
      const {long_name: num = null} = place.address_components.find(c => c.types.includes('street_number')) || {};
      const {long_name: street = null} = place.address_components.find(c => c.types.includes('route')) || {};
      const {long_name: postalCode = null} = place.address_components.find(c => c.types.includes('postal_code')) || {};
      const {long_name: city = null} = place.address_components.find(c => c.types.includes('locality')) || place.address_components.find(c => c.types.includes('administrative_area_level_3')) || {};
      const {long_name: adminArea = null} = place.address_components.find(c => c.types.includes('administrative_area_level_1')) || {};
      const {long_name: adminArea2 = null} = place.address_components.find(c => c.types.includes('administrative_area_level_2')) || place.address_components.find(c => c.types.includes('administrative_area_level_3')) || {};

      if(!street || !postalCode || !city || !adminArea2 || !num) {
        if(!num && !postalCode) {
          toast.warning('El número de calle es obligatorio para esta dirección');
          return;
        } else {
          if(!street || !postalCode || !adminArea2) {
            toast.warning('Faltan datos en la dirección');
            return;
          }
        }
      }
      const selectedAddress = street + (num ? `, ${num}` : '') + (extra ? ` ${extra}` : '')
      setSelected(selectedAddress)
 
      onSelectResult({
        direccion: place.types.find(t => t === 'establishment') ? `${street}, ${num} ${extra}` : selectedAddress,
        codigo_postal: postalCode,
        poblacion: city,
        area: `${adminArea2}, ${adminArea}`
      })
    };

    const handleOnBlur = () => {
      if(!watch('codigo_postal')) {
        setSelected('')
        return setError('direccion', 'select-option')
      }
      clearErrors('direccion')
    }
    
    return (
        <PlacesAutocomplete
          value={selected}
          searchOptions={{
            types: ['street_address', 'route', 'car_dealer', 'car_rental', 'car_repair'],
            language: 'es',
            componentRestrictions: {
              country: [country],
            }
          }}
          onChange={handleSelectPlace}
          onSelect={handleSelect}
          onError={(status, clearSuggestions) => {
            if(status === 'ZERO_RESULTS') {
              console.log('No hay resultados');
              toast.warning('No hay resultados');
            } else {
              console.log('Google Maps API returned error with status: ', status)
              toast.error('Google Maps API returned error with status: ', status)
            }
            
            clearSuggestions()
          }}
        >
          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
            <div className="places-search form-input large">
              <input
                id='dir-direccion'
                {...getInputProps({
                  disabled: disabled
                })}
                required
                autoComplete="new-password"
                onBlur={handleOnBlur}
              />
              <label htmlFor='dir-direccion'>
                {t('carrito.envio.form.direccion')} *
              </label>
              <div className="places-search-container">
                {loading && <div className="suggestion-item">Loading...</div>}
                {suggestions.map((suggestion, i) => {
                  const className = suggestion.active
                    ? 'suggestion-item-active'
                    : 'suggestion-item';
                  // inline style for demonstration purpose
                  const style = suggestion.active
                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                      key={'place-suggestion-' + i}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
              </div>
              {errors.direccion && <span role='alert'><FontAwesomeIcon icon={faCircleXmark} /> {t('carrito.envio.form.errors.direccion')}</span>}
            </div>
          )}
        </PlacesAutocomplete>
      );
}